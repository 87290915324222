import _defaults from 'lodash/defaults';
import ajax from '@/utils/ajax';
import WS from '@/utils/websocket';
import { chat as settings } from '@/settings';
import { RESTError } from './rest';

export default class {
    get settings() {
        return settings;
    }
    get ws() {
        if (!this.__ws) {
            this.__ws = new WS({
                url: this.settings.wsUrl + '/',
                params: {
                    room: this.settings.room,
                    token: this.settings.token
                }
            });
        }
        return this.__ws;
    }
    openChat() {
        this.ws.open();
    }
    closeChat(code, messages) {
        this.ws.close(code, messages);
    }
    onEvent(event, handler) {
        this.ws.on(event, handler);
    }
    offEvent(event, handler) {
        this.ws.off(event, handler);
    }
    sendMessage(data) {
        this.ws.send(data);
    }
    getMessages(params) {
        return ajax.get({
            url: `${this.settings.url}/messages/`,
            params: _defaults({ room: this.settings.room }, params),
            headers: { 'Authorization': `Token ${this.settings.token}` }
        }).then((response) => {
            return response.data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при загрузке сообщений чата', { request: { params } });
        });
    }
}
