import { banking as settings } from '@/settings';
import { REST, RESTError } from '../rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'tinkoff';
    }
    static getOrCreateClient(inn, id, data={}) {
        return this.getClient(inn, id).then((data) => {
            return data;
        }, (error) => {
            if (error.response && error.response.data && error.response.data.code == 7) {
                return this.createClient(inn, id, data)
            }
            throw error;
        });
    }
    static getClient(inn, id) {
        return this._get(`tinkoff/${inn}/client/${id}`).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении клиента', { request: { inn, id } });
        });
    }
    static createClient(inn, id, data={}) {
        return this._put(`tinkoff/${inn}/client/${id}`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании клиента', { request: { inn, id, data } });
        });
    }
    static deleteClient(inn, id) {
        return this._delete(`tinkoff/${inn}/client/${id}`).catch((error) => {
            throw new RESTError(error, 'Ошибка при удалении клиента', { request: { inn, id } });
        });
    }
    static getCards(inn, clientId) {
        const params = {
            client_id: clientId
        };
        return this._get(`tinkoff/${inn}/card`, params).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении карт', { request: { inn, params } });
        });
    }
    static createCard(inn, clientId, data={}) {
        const params = {
            client_id: clientId
        };
        return this._post(`tinkoff/${inn}/card`, params, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании карты', { request: { inn, params, data } });
        });
    }
    static deleteCard(inn, clientId, id) {
        const params = {
            client_id: clientId
        };
        return this._delete(`tinkoff/${inn}/card/${id}`, params).catch((error) => {
            throw new RESTError(error, 'Ошибка при удалении карты', { request: { inn, id, params } });
        });
    }
    static getCardCheckTypes(inn) {
        return this._get(`tinkoff/${inn}/card/check-type`, {}, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении типов проверок карт', { request: { inn } });
        });
    }
    static executePayment(inn, data) {
        return this.createPayment(inn, data).then((data) => {
            return this.proceedPayment(inn, data.id);
        });
    }
    static createPayment(inn, data) {
        return this._post(`tinkoff/${inn}/payment`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании платежа', { request: { inn, data } });
        });
    }
    static proceedPayment(inn, id) {
        return this._put(`tinkoff/${inn}/payment/${id}`).catch((error) => {
            throw new RESTError(error, 'Ошибка при проведении платежа', { request: { inn, id } });
        });
    }
}
