<template>
    <mask-input
        :value="value"
        mask="+7 999 999 99 99"
        @change="change($event)"
    />
</template>

<script>
    import maskInput from './mask-input.vue';

    export default {
        components: {
            maskInput
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            }
        },
        methods: {
            change(value) {
                this.$emit('change', value);
            }
        }
    };
</script>
