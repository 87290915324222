import { banking as settings } from '@/settings';
import { REST, RESTError } from './rest';
import tinkoff from './banking/tinkoff';
import transkapital from './banking/transkapital';
import chelinvest from './banking/chelinvest';
import alfabank from './banking/alfabank';
import sberbank from './banking/sberbank';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'banking';
    }
    static getOrganizations(params) {
        return this._get('organization', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении организаций', { request: { params } });
        });
    }
    static getBanks(params) {
        return this._get('bank', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении банков', { request: { params } });
        });
    }
    static getTransaction(id) {
        return this._get(`transaction/${id}`).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении транзакции', { request: { id } });
        });
    }
    static updateTransaction(id, data) {
        return this._put(`transaction/${id}`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при обновлении транзакции', { request: { id, data } });
        });
    }
    static get tinkoff() {
        return tinkoff;
    }
    static get transkapital() {
        return transkapital;
    }
    static get chelinvest() {
        return chelinvest;
    }
    static get alfabank() {
        return alfabank;
    }
    static get sberbank() {
        return sberbank;
    }
}
