<template>
    <mask-input
        :value="val"
        mask="99.99.9999"
        @change="change($event)"
    />
</template>

<script>
    import { parseDate, formatDate } from '@/utils/helpers';
    import maskInput from './mask-input.vue';

    export default {
        components: {
            maskInput
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                val: this.encode(this.value)
            };
        },
        watch: {
            value(value) {
                this.val = this.encode(value);
            }
        },
        methods: {
            change(value) {
                this.$emit('change', this.decode(value));
            },
            encode(value) {
                if (!value) {
                    return '';
                }
                return formatDate(parseDate(value, 'YYYY-MM-DD'), 'DD.MM.YYYY');
            },
            decode(value) {
                if (!value) {
                    return '';
                }
                return formatDate(parseDate(value, 'DD.MM.YYYY'), 'YYYY-MM-DD');
            }
        }
    };
</script>
