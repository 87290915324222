import { pawns as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'pawns';
    }
    static getTypes() {
        return Promise.resolve(settings.types);
    }
    static createPawn(data) {
        return this._post('pawns', {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании залога', { request: { data } });
        });
    }
    static getQuestions(params) {
        return this._get('pawns/questions', params).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении вопросов анкеты', { request: { params } });
        });
    }
    static getSchemes(params) {
        return this._get('pawns/schemes', params).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении схем кредитования', { request: { params } });
        });
    }
    static getPawnSum(data) {
        return this._post('pawns/sum', {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении суммы залога', { request: { data } });
        });
    }
}
