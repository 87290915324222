import { documents as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'documents';
    }
    static getTypes(params) {
        return this._get('types', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении типов документов', { request: { params } });
        });
    }
    static getCountries(params) {
        return this._get('countries', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении стран', { request: { params } });
        });
    }
    static checkDocuments(data) {
        return this._post('types/check', {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке документов', { request: { data } });
        });
    }
}
