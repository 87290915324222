import { banking as settings } from '@/settings';
import { REST, RESTError } from '../rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'transkapital';
    }
    static executePayment(inn, data) {
        return this.createPayment(inn, data);
    }
    static createPayment(inn, data) {
        return this._post(`transkapital/${inn}/payment`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании платежа', { request: { inn, data } });
        });
    }
}
