<template>
    <div class="symbol">
        <input
            ref="input"
            v-bind="$attrs"
            :class="inputClass"
            :value="value"
            :disabled="disabled"
            :readonly="readonly"
            class="symbol__input"
            @change="change($event.target.value)"
        >
        <div
            v-if="symbols.length"
            class="symbol__board"
        >
            <a
                v-for="symbol in symbols"
                href="#"
                class="symbol__key"
                :class="symbolClass(symbol)"
                @click.prevent="insertSymbol(symbol.value)"
            >
                <span class="symbol__sign">{{ symbol.value }}</span>
                <span class="symbol__name">{{ symbol.name }}</span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        inheritAttrs: false,
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: undefined
            },
            disabled: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            inputClass: {
                type: [ Object, Array, String ],
                default: undefined
            }
        },
        data() {
            return {
                val: this.encode(this.value),
                symbols: [
                    { name: 'точка снизу', value: '.' },
                    { name: 'точка посередине', value: '·' },
                    { name: 'точка сверху', value: '˙' },
                    { name: 'двоеточие', value: ':' },
                    { name: 'затертый символ', value: '*' }
                ]
            };
        },
        watch: {
            value(value) {
                this.val = this.encode(value);
            }
        },
        methods: {
            change(value) {
                this.$emit('change', this.decode(value));
            },
            insertSymbol(value) {
                if (this.disabled || this.readonly) {
                    return;
                }

                let input = this.$refs.input,
                    startPos = undefined,
                    endPos = undefined;

                if (document.selection) {
                    input.focus();
                    let sel = document.selection.createRange();
                    sel.text = value;
                } else if (input.selectionStart || input.selectionStart == '0') {
                    startPos = input.selectionStart;
                    endPos = input.selectionEnd;
                    input.value = input.value.substring(0, startPos) + value + input.value.substring(endPos, input.value.length);
                } else {
                    input.value += value;
                }

                let pos = (startPos !== undefined) ? startPos + value.length : input.value.length;

                if (input.setSelectionRange) {
                    input.focus();
                    input.setSelectionRange(pos, pos);
                } else if (input.createTextRange) {
                    let range = input.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', pos);
                    range.moveStart('character', pos);
                    range.select();
                }

                this.change(input.value);
            },
            symbolClass(symbol) {
                return {
                    'is-disabled': this.disabled || this.readonly
                };
            },
            encode(value) {
                if (!value) {
                    return '';
                }
                return value;
            },
            decode(value) {
                if (!value) {
                    return '';
                }
                return value;
            }
        }
    };
</script>
