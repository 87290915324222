import { clients as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'clients';
    }
    static getClients(params) {
        return this._get('clients', params).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении клиентов', { request: { params } });
        });
    }
    static getClient(id) {
        return this._get(`clients/${id}`).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении клиента', { request: { id } });
        });
    }
    static createClient(data) {
        return this._post('clients', {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании клиента', { request: { data } });
        });
    }
    static updateClient(id, data) {
        return this._put(`clients/${id}`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при обновлении клиента', { request: { id, data } });
        });
    }
    static checkClient(id) {
        return this._post(`clients/${id}/check`).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке клиента', { request: { id } });
        });
    }
    static requestVerification(id) {
        return this._post(`clients/${id}/request-verification`).catch((error) => {
            throw new RESTError(error, 'Ошибка при запросе верификации клиента', { request: { id } });
        });
    }
    static confirmVerification(id, data) {
        return this._post(`clients/${id}/confirm-verification`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при подтверждении верификации клиента', { request: { id, data } });
        });
    }
}
