<template>
    <div
        class="icon-select"
        :class="className"
    >
        <div
            v-if="options.length"
            class="icon-select__list"
        >
            <div
                v-for="option in options"
                class="icon-select__item"
                :class="{ 'is-active': isActive(option) }"
                @click="select(option)"
            >
                <div
                    class="icon-select__icon"
                    :class="iconClass(option)"
                    :style="iconStyle(option)"
                    :title="option.name"
                />
            </div>
        </div>
        <div
            v-else
            class="icon-select__empty"
        >
            <slot name="empty">
                Список пуст
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: [String, Number],
                default: ''
            },
            options: {
                type: Array,
                default() { return []; }
            },
            name: {
                type: String,
                default: undefined
            },
            disabled: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            className() {
                let classes = [];
                if (this.name) {
                    classes.push('icon-select--name_' + this.name);
                }
                if (this.disabled || this.readonly) {
                    classes.push('is-disabled');
                }
                return classes;
            }
        },
        methods: {
            isActive(option) {
                return this.value === option.value;
            },
            select(option) {
                if (!this.disabled && !this.readonly && !option.disabled) {
                    let value = this.isActive(option) ? '' : option.value;
                    this.$emit('change', value);
                }
            },
            iconClass(option) {
                let classes = [];
                if (option.icon) {
                    classes.push('icon-select__icon--' + option.icon);
                }
                if (option.disabled) {
                    classes.push('is-disabled');
                }
                return classes;
            },
            iconStyle(option) {
                return option.src ? `background-image: url('${option.src}');` : '';
            }
        }
    };
</script>
