const ajax = {
    timeout: 45000,
    responseType: 'json',
    responseEncoding: 'utf8'
};

const websocket = {
    url: '/ws/',
    protocols: []
};

const cache = {
    storage: 'sessionStorage'
};

const logger = {
    url: '//backend.fianitlombard.ru/logger',
    level: 'info',
    token: 'b7af2e96fefe90d918fd06c677279abd39fb4da4'
};

const chat = {
    url: '//backend.fianitlombard.ru/chat',
    wsUrl: '//backend.fianitlombard.ru/ws/chat',
    room: 'merchant',
    token: 'b7af2e96fefe90d918fd06c677279abd39fb4da4'
};

const news = {
    url: '//backend.fianitlombard.ru/news',
    token: 'b7af2e96fefe90d918fd06c677279abd39fb4da4'
};

const documents = {
    url: '//backend.fianitlombard.ru/documents',
    token: 'b7af2e96fefe90d918fd06c677279abd39fb4da4'
};

const clients = {
    url: '//backend.fianitlombard.ru/clients',
    token: 'b7af2e96fefe90d918fd06c677279abd39fb4da4'
};

const merchants = {
    url: '//backend.fianitlombard.ru/merchants',
    token: 'b7af2e96fefe90d918fd06c677279abd39fb4da4'
};

const pawns = {
    url: '//backend.fianitlombard.ru/pawns',
    token: 'b7af2e96fefe90d918fd06c677279abd39fb4da4',
    types: [
        { name: 'Ювелирные изделия', code: 'jewel', icon: 'jewel' }
        //{ name: 'Неювелирные изделия', code: 'product', icon: 'product' },
        //{ name: 'Автомобильный залог', code: 'auto', icon: 'auto' }
    ]
};

const jewels = {
    url: '//backend.fianitlombard.ru/jewels',
    token: 'b7af2e96fefe90d918fd06c677279abd39fb4da4',
    stamp_type: [
        { name: 'Нет', code: 'none' },
        { name: 'Заводской', code: 'factory' },
        { name: 'Мастерской', code: 'master' },
        { name: 'Импортный', code: 'import' }
    ],
    indent_type: [
        { name: 'Нет', code: 'none' },
        { name: 'Отечественное', code: 'domestic' },
        { name: 'Импортное', code: 'import' }
    ]
};

const banking = {
    url: '//backend.fianitlombard.ru/banking',
    token: 'b7af2e96fefe90d918fd06c677279abd39fb4da4'
};

const printforms = {
    url: '//backend.fianitlombard.ru/printforms',
    token: 'b7af2e96fefe90d918fd06c677279abd39fb4da4'
};

const fias = {
    url: '//service.2450055.ru:40580/fias',
    token: '7e57176598334d525435e17aa16a884c89e44d35'
};

export {
    ajax,
    websocket,
    cache,

    logger,
    chat,
    news,
    documents,

    clients,
    merchants,
    pawns,
    jewels,
    banking,
    printforms,

    fias
};
