import date from './date';
import price from './price';
import wordform from './wordform';
import datecard from './datecard';

export default {
    date,
    price,
    wordform,
    datecard
};
