import { banking as settings } from '@/settings';
import { REST, RESTError } from '../rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'sberbank';
    }
    static executePayment(inn, data) {
        return this.createOrder(inn, data).then((data) => {
            return this.createPaymentOrder(inn, data).then((data) => {
                return this.createPayment(inn, data.id);
            })
        });
    }
    static createOrder(inn, data) {
        return this._post(`sberbank/${inn}/order`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании платежа (1)', { request: { inn, data } });
        });
    }
    static createPaymentOrder(inn, data) {
        return this._post(`sberbank/${inn}/payment/create_order_payment`, {}, data).then((data) => {
            return data.order_p2p;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании платежа (2)', { request: { inn, data } });
        });
    }
    static createPayment(inn, data) {
        return this._post(`sberbank/${inn}/payment`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании платежа (3)', { request: { inn, data } });
        });
    }
}
