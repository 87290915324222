import { banking as settings } from '@/settings';
import { REST, RESTError } from '../rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'chelinvest';
    }
    static getCards(inn, clientId) {
        const params = {
            client_id: clientId
        };
        return this._get(`chelinvest/${inn}/card`, params).then((data) => {
            return data.results.tokens;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении карт', { request: { inn, params } });
        });
    }
    static createCard(inn, clientId, phone) {
        const data = {
            client_id: clientId,
            phone: phone
        };
        return this._post(`chelinvest/${inn}/card`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании карты', { request: { inn, clientId, data } });
        });
    }
    static createPayment(inn, data={}) {
        return this._post(`chelinvest/${inn}/payment`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при создании платежа', { request: { inn, data } });
        });
    }
    static resendCode(inn, data={}) {
        return this._post(`chelinvest/${inn}/payment/resend_pep`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при повторной отправке кода', { request: { inn, data } });
        });
    }
    static executePayment(inn, data) {
        return this._post(`chelinvest/${inn}/payment/check_pep`, {}, data).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при подтверждении платежа', { request: { inn, data } });
        });
    }
}
