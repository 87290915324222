import { news as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'news';
    }
    static getNews(params) {
        return this._get('news', params).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении новостей', { request: { params } });
        });
    }
}
