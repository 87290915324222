import { fias as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'fias';
    }
    static getLevels() {
        return this._get('levels', {}, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении уровней');
        });
    }
    static getInitial(params) {
        return this._get('initial', params, {}, true).catch((error) => {
            throw new RESTError(error, 'Ошибка при инициализационных значений');
        });
    }
    static getDetail(params) {
        return this._get('detail', params, {}, true).catch((error) => {
            throw new RESTError(error, 'Ошибка при детализации');
        });
    }
    static searchObjects(params) {
        return this._get('search', params).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при поиске объектов');
        });
    }
    static getObjects(params) {
        return this._get('objects', params).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении объектов');
        });
    }
    static getHouses(params) {
        return this._get('houses', params).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении домов');
        });
    }
    static getRooms(params) {
        return this._get('rooms', params).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении помещений');
        });
    }
}
