import { merchants as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'merchants';
    }
    static authorizeMerchant(data) {
        return this._post('merchants/auth', {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при авторизации товароведа', { request: { data } });
        });
    }
    static deauthorizeMerchant() {
        return Promise.resolve();
    }
    static requestSES(id, data) {
        return this._post(`merchants/${id}/request-ses`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при запросе ПЭП товароведа', { request: { id, data } });
        });
    }
    static confirmSES(id, data) {
        return this._post(`merchants/${id}/confirm-ses`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при подтверждении ПЭП товароведа', { request: { id, data } });
        });
    }
    static requestPhone(id, data) {
        return this._post(`merchants/${id}/request-phone`, {}, data).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении телефона товароведа', { request: { id, data } });
        });
    }
}
