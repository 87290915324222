import { jewels as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'jewels';
    }
    static getGroups(params) {
        return this._get('groups', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении групп изделий', { request: { params } });
        });
    }
    static getTypes(params) {
        return this._get('types', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении типов изделий', { request: { params } });
        });
    }
    static getMetals(params) {
        return this._get('metals', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении металлов изделий', { request: { params } });
        });
    }
    static getStandards(params) {
        return this._get('standards', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении проб изделий', { request: { params } });
        });
    }
    static getCuts(params) {
        return this._get('cuts', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении огранок изделий', { request: { params } });
        });
    }
    static getInserts(params) {
        return this._get('inserts', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении вставок изделий', { request: { params } });
        });
    }
    static evalInsert(id, data) {
        return this._post(`inserts/${id}/eval`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при оценке вставки изделия', { request: { id, data } });
        });
    }
    static getStampTypes() {
        return Promise.resolve(this.settings.stamp_type);
    }
    static getStamps(params) {
        return this._get('stamps', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении форм именника', { request: { params } });
        });
    }
    static checkJewel(data) {
        return this._post('stamps/check', {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке подлинности изделия', { request: { data } });
        });
    }
    static getIndentTypes() {
        return Promise.resolve(this.settings.indent_type);
    }
    static getIndents(params) {
        return this._get('indents', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении форм клейма', { request: { params } });
        });
    }
    static getMarks(params) {
        return this._get('marks', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении знаков удостоверения', { request: { params } });
        });
    }
    static getPositions(params) {
        return this._get('positions', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении расположений клейма', { request: { params } });
        });
    }
    static getAttrs(params) {
        return this._get('attrs', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении атрибутов изделий', { request: { params } });
        });
    }
    static getDefects(params) {
        return this._get('defects', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении дефектов изделий', { request: { params } });
        });
    }
    static getCategories(params) {
        return this._get('categories', params, {}, true).then((data) => {
            return data.results;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении категорий изделий', { request: { params } });
        });
    }
    static recognizeCategory(data) {
        return this._post('categories/recognize', {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при определении категории изделия', { request: { data } });
        });
    }
    static evalCategory(id, data) {
        return this._post(`categories/${id}/eval`, {}, data).catch((error) => {
            throw new RESTError(error, 'Ошибка при оценке категории изделия', { request: { id, data } });
        });
    }
    static evalJewel(item, inserts=[]) {
        const jewel = {
            weight: item.weight,
            type: item.type,
            metal: item.metal,
            standard: item.standard,
            attrs: item.attrs,
            defects: item.defects
        };

        return this.recognizeCategory(jewel).then((category) => {
            let promises = [
                Promise.resolve(category),
                this.evalCategory(category.code, jewel)
            ];
            for (let insert of inserts) {
                promises.push(this.evalInsert(insert.insert, insert));
            }
            return Promise.all(promises);
        }).then((values) => {
            const category = values.shift();
            let price = 0;
            for (let value of values) {
                price += value.price;
            }
            return {
                category,
                price
            };
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при оценке изделия', { request: { item, inserts } });
        });
    }
}
