import { printforms as settings } from '@/settings';
import ajax from '@/utils/ajax';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }
    static get serviceName() {
        return 'printforms';
    }
    static generate(id, format, data) {
        return ajax.post({
            url: `${this.settings.url}/printform/${id}/generate/${format}/`,
            data: { data },
            headers: this._getAuthHeaders(),
            responseType: 'blob'
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении печатной формы', { request: { id, format, data } });
        });
    }
}
